
























































































































































import { Component } from "vue-property-decorator";
//import { Status } from "../../../types/Status";
import Request from "../../../services/request";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";

import { Result } from "../../../types/dashboard/amountDay";
@Component({
  components: {
    List,
  },
})
export default class AmountStatus extends Request {
  list: Result[] = [];
  totalCountA: number = 0;
  totalCountP: number = 0;
  totalCountF: number = 0;
  totalCount: number = 0;
  totalCountS: number = 0;
  intervalid: number | NodeJS.Timeout | null = null;

  async mounted() {
    this.updateCounts();
    this.intervalid = setInterval(await this.updateCounts, 10000);
  }

  async updateCounts()  {
    const result = await this.get(this.paths.pathMetrics, null, false);
    this.list = result;

    this.totalCountA = result.agents.onlineAgents;
    this.totalCountP = result.agents.onBreakAgents;
    this.totalCountF = result.agents.offlineAgents;
    this.totalCountS = result.agents.noStatusAgents;

    this.totalCount =
      this.totalCountA + this.totalCountP + this.totalCountF + this.totalCountS;
  }
  async beforeDestroy() {
    if (this.intervalid) {
      clearInterval(this.intervalid as number);
    }
  }
}
