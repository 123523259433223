
























































































































































import { Component } from "vue-property-decorator";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import Request from "../../../services/request";
import { Result } from "../../../types/dashboard/amountDay";

@Component({
  components: {
    List,
  },
})
export default class AmountDay extends Request {
  list: Result[] = [];

  totalCountDay: number = 0;

  totalCountWaiting: number = 0;
  totalCountpending: number = 0;
  totalCountService: number = 0;
  intervalid: number | NodeJS.Timeout | null = null;

  mounted() {
    this.updateCounts();
    this.intervalid = setInterval(this.updateCounts, 10000);
  }

  async updateCounts()  {
    const result = await this.get(this.paths.pathMetrics, null, false);
    this.list = result;
    this.totalCountWaiting = result.attendencies.waitingAttendency;

    this.totalCountpending = result.attendencies.pendingAttendency;
    this.totalCountService = result.attendencies.agentAttendency;

    this.totalCountDay =
      this.totalCountWaiting + this.totalCountpending + this.totalCountService;
  }

  async beforeDestroy() {
    if (this.intervalid) {
      clearInterval(this.intervalid as number);
    }
  }
}
