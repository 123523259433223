






























import { Component, Vue } from "vue-property-decorator";
import CardsAmount from "./charts/CardsAmount.vue";
import AmountDay from "./charts/AmountDay.vue";
import AmountChannel from "./charts/AmountChannel.vue";
import AmountMonth from "./charts/AmountMonth.vue";
import AmountStatus from "./charts/AmountStatus.vue";

@Component({
  components: {
    CardsAmount,
    AmountDay,
    AmountChannel,
    AmountMonth,
    AmountStatus,
  },
})
export default class Dashboard extends Vue {}
