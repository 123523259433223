<template>
  <v-card class="card-shadow border-radius-xl px-5 py-5" height="27rem"> 
    <div class="bg-gradient-default border-radius-lg py-3 pe-1 mb-6">
      <div class="chart-area">
        <canvas :height="170" :id="amountChannelId"></canvas>
      </div>
    </div>
    <h6 class="ms-2 mt-6 mb-0 text-h6 text-typo font-weight-bold">
      {{ $t("amountChannel") }}
    </h6>

    <div class="container border-radius-lg ms-2">
      <div class="row">
        <div class="col-3 py-3 ps-0">
          <div class="d-flex align-center mb-2">
            <v-avatar
              size="40"
              class="text-white bg-gradient-success shadow px-1 py-1 me-2"
              rounded
            >
              <v-icon size="30">mdi-whatsapp</v-icon>
            </v-avatar>
            <p class="text-xs mt-1 mb-0 font-weight-bold text-body">
              {{ $t("whatsapp") }}
            </p>
          </div>
          <h4 class="font-weight-bolder text-h4 mb-2">1</h4>
        </div>
        <div class="col-3 py-3 ps-0">
          <div class="d-flex align-center mb-2">
            <v-avatar
              size="40"
              class="text-white bg-gradient-info px-1 py-1 me-2"
              rounded
            >
              <v-icon size="30">mdi-telegram</v-icon>
            </v-avatar>
            <p class="text-xs mt-1 mb-0 font-weight-bold text-body">
              {{ $t("telegram") }}
            </p>
          </div>
          <h4 class="font-weight-bolder text-h4 mb-2">1</h4>
        </div>
        <div class="col-3 py-3 ps-0">
          <div class="d-flex align-center mb-2">
            <v-avatar
              size="40"
              class="text-white bg-gradient-warning px-1 py-1 me-2"
              rounded
            >
              <v-icon size="30">mdi-chat</v-icon>
            </v-avatar>
            <p class="text-xs mt-1 mb-0 font-weight-bold text-body">
              {{ $t("webChat") }}
            </p>
          </div>
          <h4 class="font-weight-bolder text-h4 mb-2">43</h4>
        </div>
        <div class="col-3 py-3 ps-0">
          <div class="d-flex align-center mb-2">
            <v-avatar
              size="40"
              class="text-white bg-gradient-danger px-1 py-1 me-2"
              rounded
            >
              <v-icon size="30">mdi-instagram</v-icon>
            </v-avatar>
            <p class="text-xs mt-1 mb-0 font-weight-bold text-body">
              {{ $t("instagram") }}
            </p>
          </div>
          <h4 class="font-weight-bolder text-h4 mb-2">43</h4>
        </div>
      </div>
    </div>
  </v-card>
</template>
<script>
import Chart from "chart.js/auto";

export default {
  data: () => ({
    amountChannelId: "amountChannel",
    chartId: "bar-chart",
    datasetIdKey: "label",
    width: 400,
    height: 400,
    cssClasses: "",

    plugins: [],

    chartData: {
      type: "bar",
      data: {
        labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [
          {
            label: "Sales",
            borderWidth: 0,
            borderRadius: 4,
            borderSkipped: false,
            backgroundColor: "#fff",
            data: [450, 200, 100, 220, 500, 100, 400, 230, 500],
            maxBarThickness: 6,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              padding: 15,
              font: {
                size: 14,
                family: "Open Sans",
                style: "normal",
                lineHeight: 2,
              },
              color: "#fff",
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              display: false,
            },
          },
        },
      },
    },
  }),
  mounted() {
    new Chart(
      document.getElementById(this.amountChannelId).getContext("2d"),
      this.chartData
    );
  },
};
</script>
