<template>
  <v-card class="card-shadow border-radius-xl" height="27rem">
    <div class="card-header-padding pb-0">
      <div class="font-weight-bold text-typo text-h6 text-typo mb-2">
        {{ $t("amountMonth") }}
      </div>
      <p class="text-body text-sm mb-0">
        <i class="fa fa-arrow-up text-success" aria-hidden="true"></i>
        <span class="font-weight-bold ms-1">4% more</span> in 2021
      </p>
    </div>
    <v-card-text class="card-padding">
      <div class="chart-area">
        <canvas :height="300" :id="amountMonthId"></canvas>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  data: () => ({
    amountMonthId: "amountMonth",
    chartId: "bar-chart",
    datasetIdKey: "label",
    width: 400,
    height: 400,
    cssClasses: "",

    plugins: [],

    planetChartData: {
      type: "line",
      data: {
        labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [
          {
            label: "Mobile apps",
            tension: 0.4,
            pointRadius: 0,
            borderColor: "#17ad37",
            borderWidth: 3,
            fill: true,
            data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
            maxBarThickness: 6,
          },
          {
            label: "Websites",
            tension: 0.4,
            pointRadius: 0,
            borderColor: "#3A416F",
            borderWidth: 3,
            fill: true,
            data: [30, 90, 40, 140, 290, 290, 340, 230, 400],
            maxBarThickness: 6,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              padding: 10,
              color: "#b2b9bf",
              font: {
                size: 11,
                family: "Open Sans",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              color: "#b2b9bf",
              padding: 20,
              font: {
                size: 11,
                family: "Open Sans",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
        },
      },
    },
  }),
  mounted() {
    new Chart(
      document.getElementById(this.amountMonthId).getContext("2d"),
      this.planetChartData
    );
  },
};
</script>
